import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';

import { getCSRFToken } from '@xing-com/crate-login-utils';

import { MAP_RESPONSE_FIELDS_TO_NAMES, INITIAL_FORM_VALUES } from './constants';
import messages from './messages';
import type {
  TouchedFields,
  Values,
  FormErrors,
  ParsedCvData,
  useFormActionsType,
  UseFormActionsReturnType,
  ErrorMap,
  ResponseFields,
  ErrorAccumulator,
} from './types';
import { useValuesToPayload } from './utils';

const useFormActions = ({
  signupChannel,
  handleChange,
  prefilledValues,
  neededFields = ['firstName', 'lastName', 'email', 'password', 'tandcCheck'],
  emailOnly = false,
  externalUrl,
  submitAfterRecaptcha = false,
  onShowRecaptcha = () => null,
  onError = () => null,
  externalSubmit,
  onSubmitSuccess,
}: useFormActionsType): UseFormActionsReturnType => {
  const { formatMessage } = useIntl();
  const [submitCount, setSubmitCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [touched, setTouched] = useState<TouchedFields>({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const formErrorsInitialState = {
    base: null,
    emailDomainError: null,
    secondaryEmailError: null,
  };

  const [formErrors, setFormErrors] = useState<FormErrors>(
    formErrorsInitialState
  );

  const INITIAL_VALUES: Values = {
    ...INITIAL_FORM_VALUES,
    signupChannel,
    ...prefilledValues,
    parsedCvId: null,
  };

  const [values, setValues] = useState<Values>(INITIAL_VALUES);

  useEffect(() => {
    if (!enabledCheck) return;
    setIsDisabled(!enabledCheck(values));

    //submits after recaptcha as been done
    if (
      values.gRecaptchaResponse !== '' &&
      !isSubmitting &&
      submitAfterRecaptcha
    ) {
      externalSubmit
        ? externalSubmit({
            values,
            formActions: { setFormErrors, setIsSubmitting },
            neededFields,
            setValues,
          })
        : submit();
    }
  }, [values]);

  const isTouched = (property: keyof Values): boolean => {
    const changed = touched[property] && values[property] !== '';
    const submitted = submitCount > 0;
    return changed || submitted;
  };

  const handleResetCvUpload = (): void => {
    setValues({
      ...values,
      parsedCvId: null,
    });
  };

  const handleParsedCvDataReceived = async ({
    id,
  }: ParsedCvData): Promise<boolean> => {
    try {
      setValues({
        ...values,
        ...(id && { parsedCvId: id }),
      });
      setTouched({
        ...touched,
        ...(id && { parsedCvId: false }),
      });

      return !!id;
    } catch (e) {
      return false;
    }
  };

  const maybeError = (property: keyof Values): string | undefined => {
    if (
      formErrors[property] === null ||
      !isTouched(property) ||
      typeof formErrors[property] === 'boolean'
    ) {
      return undefined;
    }

    return translateError(formErrors[property]);
  };

  const translateError = (
    error?: string | boolean | MessageDescriptor | null | undefined
  ): string | undefined => {
    if (!error || typeof error === 'boolean') return undefined;

    return typeof error === 'string'
      ? formatMessage({ id: error })
      : formatMessage(error);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    handleChange && handleChange(e);
    const fieldName = e.target?.name;
    const fieldValue = e.target?.value;

    setTouched((previousTouched) => ({
      ...previousTouched,
      [fieldName]: true,
    }));

    setValues((previousValues) => ({
      ...previousValues,
      [fieldName]: fieldValue,
    }));

    setFormErrors((previousErrors) => ({
      ...previousErrors,
      [fieldName]: null,
    }));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormErrors((previousErrors) => ({
      ...previousErrors,
      emailDomainError: null,
      secondaryEmailError: null,
    }));
    setValues((values) => ({
      ...values,
      skipEmailDomainCheck: false,
      checkSecondaryFields: true,
    }));
    handleInputChange(e);
  };

  const enabledCheck = (values: Values): boolean =>
    neededFields
      .map((neededField) => !!values[neededField])
      .every((field) => field);

  const reduceErrors = (
    errors: ErrorMap,
    values: Partial<Values> = {
      skipEmailDomainCheck: false,
      checkSecondaryFields: true,
    }
  ): ErrorMap => {
    return Object.keys(errors || {}).reduce(
      (acc: ErrorAccumulator, errorKey) => {
        const fieldName =
          MAP_RESPONSE_FIELDS_TO_NAMES[errorKey as ResponseFields];

        if (!fieldName) return acc;

        if (values.skipEmailDomainCheck && fieldName === 'emailDomain')
          return acc;
        if (
          !values.checkSecondaryFields &&
          fieldName === 'email' &&
          errors[errorKey] === 'ERROR_REGISTER_EMAIL_SECONDARY'
        )
          return acc;
        acc[fieldName] = errors[errorKey];

        return acc;
      },
      {}
    );
  };

  const payload = { ...values, signupChannel };
  const valuesToPayload = useValuesToPayload(payload, emailOnly);

  const submit = async (): Promise<void> => {
    setFormErrors(formErrorsInitialState);
    setSubmitCount(submitCount + 1);
    setShowRecaptcha(false);
    setIsSubmitting(true);

    try {
      const data = await fetch(`${externalUrl}/welcome/api/signup`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          'x-csrf-token': getCSRFToken(),
        },
        method: 'POST',
        body: JSON.stringify(valuesToPayload),
      });

      const result = await data.json();

      // Check if the response status indicates an error (e.g., 400)
      if (!data.ok) {
        throw new Error(JSON.stringify(result)); // You can throw the error object or just use a string
      }

      const { success, target, captcha } = result;
      if (success && captcha) {
        setShowRecaptcha(true);
        setIsSubmitting(false);
        return new Promise(() => {
          onShowRecaptcha();
        });
      }

      if (success && typeof onSubmitSuccess === 'function') {
        return new Promise(() => {
          onSubmitSuccess();
          setIsSubmitting(false);
        });
      }

      if (success && target) {
        return new Promise(() => {
          window.location = target;
          setIsSubmitting(false);
        });
      }
    } catch (e) {
      if (e.name === 'TimeoutError') {
        setFormErrors({
          base: messages.submitRequestError,
        });
        onError(e);
        setIsSubmitting(false);
        return;
      }

      const originalErrors = JSON.parse(e.message).errors;
      setValues({
        ...values,
        gRecaptchaResponse: '',
      });
      setIsSubmitting(false);
      setShowRecaptcha(false);

      if (typeof originalErrors === 'string') {
        setFormErrors({
          base: messages.submitRequestError,
        });
      } else {
        const reducedErrors = reduceErrors(originalErrors, values);
        setFormErrors({
          ...formErrors,
          ...reducedErrors,
          ...((reducedErrors.emailDomain || values.skipEmailDomainCheck) && {
            emailDomainError: true,
          }),
          ...(values.checkSecondaryFields && {
            secondaryEmailError:
              reducedErrors.email === 'ERROR_REGISTER_EMAIL_SECONDARY',
          }),
          ...(reducedErrors.base === 'XE_GENERIC_ERROR' && {
            base: messages.submitRequestError,
          }),
        });
      }

      onError(originalErrors);
      setIsSubmitting(false);
    }
  };

  return {
    isDisabled,
    formErrors,
    values,
    setValues,
    handleResetCvUpload,
    handleParsedCvDataReceived,
    maybeError,
    translateError,
    handleInputChange,
    handleEmailChange,
    showRecaptcha,
    onSubmit: externalSubmit
      ? () =>
          externalSubmit({
            values,
            formActions: { setFormErrors, setIsSubmitting },
            neededFields,
            setValues,
          })
      : submit,
    isSubmitting,
  };
};

export default useFormActions;
